
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import VirtualDoctorLayout from '@/custom/menicon/layouts/VirtualDoctorLayout.vue';
import { MeniconSurveyResultsService } from '@/services/api';
import { ButtonLink, DataTable } from '@/lib/components';
import { SurveyResult } from '@/models';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(relativeTime);
dayjs.extend(utc);

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    }
  },
  components: {
    VirtualDoctorLayout,
    ButtonLink,
    DataTable
  }
})
export default class VirtualDoctorSurveyResultsPage extends Vue {
  patientId!: string;
  loading = true;

  surveys: SurveyResult[] = [];
  service = new MeniconSurveyResultsService(this.patientId);

  notificationStore = useNotificationStore();

  get columns() {
    return [
      { name: 'type', label: this.$t('platform.common.type') },
      { name: 'date', label: this.$t('platform.common.completed-date') },
      { name: 'actions', label: '', align: 'right' }
    ];
  }

  async created() {
    await this.getSurveys();
  }

  async getSurveys() {
    try {
      this.loading = true;
      this.surveys = (await this.service.index()).data;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.menicon.virtual-doctor.fetch-surveys-results-error')
      });
    } finally {
      this.loading = false;
    }
  }

  formatDate(isoDate: string) {
    return this.$d(new Date(isoDate), 'shortMonth');
  }

  relativeDate(date: string) {
    return dayjs.utc().to(dayjs.utc(date));
  }
}
