import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-900" }
const _hoisted_2 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_3 = { class: "inline-flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_VirtualDoctorLayout = _resolveComponent("VirtualDoctorLayout")!

  return (_openBlock(), _createBlock(_component_VirtualDoctorLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        loading: _ctx.loading,
        columns: _ctx.columns,
        rows: _ctx.surveys,
        sort: "",
        class: "w-full mt-6"
      }, {
        "cell.type": _withCtx(({ value }) => [
          _createTextVNode(_toDisplayString(value.EN), 1)
        ]),
        "cell.date": _withCtx(({ value }) => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.formatDate(value)), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.relativeDate(value)), 1)
        ]),
        "cell.actions": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ButtonLink, {
              to: `/patients/${_ctx.patientId}/virtual-doctor/survey-results/${row.id}`,
              color: "ghost",
              "right-icon": "chevron-right"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        _: 1
      }, 8, ["loading", "columns", "rows"])
    ]),
    _: 1
  }))
}